import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"

const TermsOfUsePage = () => {
  const data = useStaticQuery(graphql`
    query PageTermsOfUseAFPAQuery {
      allStrapiTerms {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `)

  return (
    <WebsiteLayout>
      <SEO title={data.allStrapiTerms.edges[0].node.title} />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          {data.allStrapiTerms.edges[0].node.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.allStrapiTerms.edges[0].node.content,
          }}
        />
      </div>
    </WebsiteLayout>
  )
}

export default TermsOfUsePage
